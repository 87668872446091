import {
  Environment,
  Network,
  RecordSource,
  Store,
  RequestParameters,
  Variables,
  GraphQLResponse,
  Subscribable,
} from 'relay-runtime';
import createRelaySubscriptionHandler from 'graphql-ruby-client/subscriptions/createRelaySubscriptionHandler';
import {createConsumer, logger, Consumer} from '@rails/actioncable';
import {
  BE_GRAPHQL_ENDPOINT,
  FE_WS_ENDPOINT,
  CLIENT_NAME,
  CLIENT_VERSION,
  ApplicationConfiguration,
  HEADER_MOCKING_SEQUENCE_ID,
  HEADER_HC_NETWORK_SLUG,
  HEADER_HC_GQL_CLIENT_NAME,
  HEADER_HC_GQL_CLIENT_VERSION,
  COOKIE_JWT,
} from '@/config';
import PERSISTED_QUERY_MAP from '@/../persisted-queries.json';
import OPERATION_STORE from '@/../operation-store.json';
import {error} from '@/logger/client-logger';
import type {RecordMap} from 'relay-runtime/lib/store/RelayStoreTypes';

logger.enabled = ApplicationConfiguration.enableActionCableLogging;

let slug: string | null = null;
export function setSlug(newSlug: string) {
  slug = newSlug;
}

// TODO: Hoping to change how we declare this once https://github.com/eslint/eslint/issues/17128 is addressed
// https://app.clickup.com/t/862jq5j9x
function getFetchFn({sequenceId, serverCookie}: {sequenceId?: string; serverCookie?: string} = {}) {
  return async function fetchFn(
    request: RequestParameters,
    variables: Variables,
  ): Promise<GraphQLResponse | Subscribable<GraphQLResponse>> {
    const customHeaders: Record<string, string> = {
      [HEADER_HC_GQL_CLIENT_NAME]: CLIENT_NAME,
      [HEADER_HC_GQL_CLIENT_VERSION]: CLIENT_VERSION,
    };

    if (sequenceId) {
      customHeaders[HEADER_MOCKING_SEQUENCE_ID] = sequenceId;
    } else if (ApplicationConfiguration.useMockingSequenceDefaultValue) {
      // Used to support local dev against the mock server
      customHeaders[HEADER_MOCKING_SEQUENCE_ID] = '1';
    }

    if (slug) {
      customHeaders[HEADER_HC_NETWORK_SLUG] = slug;
    }

    if (serverCookie) {
      customHeaders['Cookie'] = `${COOKIE_JWT}=${serverCookie};`;
    }

    // Support for mock server to execute persisted queries since it doesn't know about the hashes
    let query: string | null = null;
    if (ApplicationConfiguration.usePersistedQueryMap && request.id) {
      query = (PERSISTED_QUERY_MAP as Record<string, string>)[request.id];
    }
    const operationId = `${CLIENT_NAME}/${request.id}`;

    const resp = await fetch(`${BE_GRAPHQL_ENDPOINT}/${request.name}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/graphql-response+json; charset=utf-8, application/json; charset=utf-8',
        'Content-Type': 'application/json',
        ...customHeaders,
      },
      body: JSON.stringify({
        ...(query ? {query} : {}),
        operationId,
        variables,
        operationName: request.name,
      }),
    });

    return await resp.json().then((json) => {
      // Ensure we log any top-level, unexpected errors we see coming back across all queries and mutations
      if (ApplicationConfiguration.logQueryErrorsJson && json.errors) {
        error(new Error(`Relay fetch: Unexpected error from ${request.name}`), undefined, {
          errors: json.errors,
          query,
          operationId,
          clientName: CLIENT_NAME,
          clientVersion: CLIENT_VERSION,
          rootNetworkSlug: slug,
        });
      }

      return json;
    });
  };
}

let openCable: Consumer | null = null;

export function closeCable() {
  if (openCable) {
    openCable.disconnect();
  }
}

function getSubscriptionHandler() {
  const cableEndpoint = new URL(FE_WS_ENDPOINT);
  cableEndpoint.searchParams.append(HEADER_HC_GQL_CLIENT_NAME, CLIENT_NAME);
  cableEndpoint.searchParams.append(HEADER_HC_GQL_CLIENT_VERSION, CLIENT_VERSION);
  if (slug) {
    cableEndpoint.searchParams.append(HEADER_HC_NETWORK_SLUG, slug);
  }
  const cable = createConsumer(cableEndpoint.toString());
  openCable = cable;
  return createRelaySubscriptionHandler({
    cable,
    operations: {
      getOperationId: (operationName: string) => {
        return `${CLIENT_NAME}/${OPERATION_STORE[operationName as keyof typeof OPERATION_STORE]}`;
      },
    },
    channelName: 'GraphQLChannel',
  });
}

function createRelayEnvironment({
  sequenceId,
  initialRecords,
  serverCookie,
}: {
  sequenceId?: string;
  initialRecords?: RecordMap;
  serverCookie?: string;
} = {}) {
  return new Environment({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore Relay library is typed incorrectly because flow is dumb: https://github.com/facebook/relay/blob/main/packages/relay-runtime/network/RelayObservable.js#L74-L76
    network: Network.create(getFetchFn({sequenceId, serverCookie}), getSubscriptionHandler()),
    store: new Store(new RecordSource(initialRecords)),
    isServer: typeof window === typeof undefined,
    // @ts-expect-error env types are incorrect and don't include relayFieldLogger
    relayFieldLogger: (event) => {
      error(new Error('Missing required relay field'), undefined, event);
    },
  });
}

let relayEnvironment: Environment | undefined;

// TODO consider renaming to getCurrentRelayEnv
export function initRelayEnvironment({
  sequenceId,
  initialRecords,
  serverCookie,
}: {
  sequenceId?: string;
  initialRecords?: RecordMap;
  serverCookie?: string;
} = {}) {
  const environment =
    !ApplicationConfiguration.reuseRelayEnvironment && relayEnvironment
      ? relayEnvironment
      : createRelayEnvironment({sequenceId, initialRecords, serverCookie});

  // For SSG and SSR always create a new Relay environment.
  if (typeof window === 'undefined') {
    return environment;
  }

  // Create the Relay environment once in the client
  // and then reuse it.
  if (!relayEnvironment || ApplicationConfiguration.reuseRelayEnvironment) {
    relayEnvironment = environment;
  }

  return relayEnvironment;
}
