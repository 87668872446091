import {LogLevel} from '@/logger/types';
import {Environment} from '@/environment';

enum VercelEnv {
  Preview = 'preview',
  Production = 'production',
  Development = 'development',
}

const VERCEL_ENV = (process.env.NEXT_PUBLIC_VERCEL_ENV || VercelEnv.Development) as VercelEnv;

/**
 * NOTE: Any values used in the front end must be prefixed with NEXT_PUBLIC_
 * in order to be propagated to the client
 * See: https://nextjs.org/docs/pages/building-your-application/configuring/environment-variables#bundling-environment-variables-for-the-browser
 */
export const MIXPANEL_KEY = process.env.NEXT_PUBLIC_MIXPANEL_KEY || '';
export const CLIENT_PREFAB_API_KEY = process.env.NEXT_PUBLIC_PREFAB_API_KEY || '';
export const POSTHOG_KEY = process.env.NEXT_PUBLIC_POSTHOG_KEY || '';
export const CLIENT_NAME = 'atlas-react';
export const CLIENT_VERSION = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA as string;
export const COOKIE_JWT = 'hcj';
export const JWT_COOKIE_DOMAIN = (process.env.NEXT_PUBLIC_JWT_COOKIE_DOMAIN ||
  process.env.NEXT_PUBLIC_VERCEL_BRANCH_URL ||
  '') as string;
export const NEW_NAV_ENABLED_USERS = process.env.NEXT_PUBLIC_NEW_NAV_ENABLED_USERS || '';
/**
 * Custom Headers
 */
export const HEADER_HC_GQL_CLIENT_NAME = 'hc-graphql-client-name';
export const HEADER_HC_GQL_CLIENT_VERSION = 'hc-graphql-client-version';
export const HEADER_HC_NETWORK_SLUG = 'hc-network-slug';
export const HEADER_MOCKING_SEQUENCE_ID = 'mocking-sequence-id';
const ENABLED_ACTION_CABLE_LOGGING = process.env.NEXT_PUBLIC_ENABLE_ACTION_CABLE_LOGGING || '';

/**
 * Dromo Constants
 *
 * Dromo is the tool that manages CSV file uploads. License key is "public" and publishable
 */
export const DROMO = {
  LICENSE_KEY: (process.env.NEXT_PUBLIC_DROMO_LICENSE_KEY || '') as string,
  WEBHOOK_URL: (process.env.NEXT_PUBLIC_DROMO_WEBHOOK_URL || '') as string,
  PRODUCTION_MODE: !!process.env.NEXT_PUBLIC_DROMO_PRODUCTION_MODE as boolean,
};

/**
 * Nylas Constants
 *
 * Nylas is the tool that manages email, contacts, and calendar integrations.
 */

export const NYLAS = {
  CLIENT_ID: (process.env.NEXT_PUBLIC_NYLAS_CLIENT_ID || '') as string,
};

/**
 * Environment constants
 *
 * APP_ENV is intended to stay in sync with NODE_ENV, but also be available on the client
 */
let APP_ENV = (process.env.NEXT_PUBLIC_APP_ENV || Environment.Test) as Environment;

/**
 * DataDog constants
 *
 * Used to explicitly configure datadog client
 */
export const DATADOG_SITE = 'datadoghq.com';
export const DATADOG_RUM_APPLICATION_ID = process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID || '';
export const DATADOG_RUM_CLIENT_TOKEN = process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN || '';
export const DATADOG_LOG_CLIENT_TOKEN = process.env.NEXT_PUBLIC_DATADOG_LOG_CLIENT_TOKEN || '';
export const DD_RUM_CX_APPLICATION_ID = process.env.NEXT_PUBLIC_DD_RUM_CX_APPLICATION_ID || '';
export const DD_RUM_CX_CLIENT_TOKEN = process.env.NEXT_PUBLIC_DD_RUM_CX_CLIENT_TOKEN || '';

/**
 * Endpoint constants
 *
 * Because we use API Routes, we need to manage urls for both the FE + BE
 */
const HTTP_PROTOCOL = VERCEL_ENV === VercelEnv.Development ? 'http' : 'https';
const WS_PROTOCOL = VERCEL_ENV === VercelEnv.Development ? 'ws' : 'wss';

// We need the BE HOST to be accessible to the FE in order to support websocket connections
const BE_HOST = process.env.NEXT_PUBLIC_BE_HOST || 'localhost:3002';
// When testing in cypress, we don't use app routes, so we want to hit the authentication host directly
const AUTH_HOST =
  APP_ENV === Environment.Test ? BE_HOST : process.env.NEXT_PUBLIC_AUTH_HOST || 'localhost:3002';
const EXTENSION_HOST = process.env.NEXT_PUBLIC_EXTENSION_HOST || 'localhost:3000';

let FE_HOST = process.env.NEXT_PUBLIC_FE_HOST || 'localhost:3000';

// When testing in cypress, we don't use app routes, so we want to hit the graphql host directly
if (APP_ENV === Environment.Test) {
  FE_HOST = BE_HOST;
  // Support dynamic preview deployments
} else if (VERCEL_ENV === VercelEnv.Preview && APP_ENV !== Environment.Uat) {
  FE_HOST = process.env.NEXT_PUBLIC_VERCEL_BRANCH_URL || FE_HOST;
  // Now make APP_ENV look like UAT for all preview environments
  APP_ENV = Environment.Uat;
}

export const DD_PROXY_PATH = 'hc/d';
export const FE_BASE_ENDPOINT = `${HTTP_PROTOCOL}://${FE_HOST}`;
export const BE_BASE_ENDPOINT = `${HTTP_PROTOCOL}://${BE_HOST}`;
// d for "d"atadog, allowing for an env var override for testing other proxies if needed
export const FE_DD_PROXY_ENDPOINT =
  process.env.NEXT_PUBLIC_DD_PROXY_OVERRIDE || `${HTTP_PROTOCOL}://${FE_HOST}/${DD_PROXY_PATH}`;
export const FE_TRACKING_ENDPOINT = 'https://app.posthog.com'; //`${FE_BASE_ENDPOINT}/ingest`;
export const BE_GRAPHQL_ENDPOINT = `${BE_BASE_ENDPOINT}/graphql`;
export const FE_WS_ENDPOINT = `${WS_PROTOCOL}://${BE_HOST}/cable`;
export const AUTH_SSO_URL = `${HTTP_PROTOCOL}://${AUTH_HOST}/authentication/sso/google/authorization`;
export const EXTENSION_URL = `${HTTP_PROTOCOL}://${EXTENSION_HOST}`;

export const ATLAS_MARKETING_SITE = 'https://exploreatlas.io';
/*
 * NodeJS Env vars only
 */
export const SERVER_PREFAB_API_KEY = process.env.PREFAB_API_KEY;

const LOG_LEVEL_MAP: Record<Environment, LogLevel> = {
  [Environment.Test]: LogLevel.Trace,
  [Environment.Development]: LogLevel.Trace,
  [Environment.Uat]: LogLevel.Info,
  [Environment.Production]: LogLevel.Info,
};

/**
 * Establishes the max log level for any given environment.
 * Given an Environment, return the max LogLevel.
 *
 * @param environment Environment to get log level for
 * @returns string (name of log level)
 */
export function getLogLevelForEnvironment(): LogLevel {
  const logLevel = LOG_LEVEL_MAP[APP_ENV];

  if (logLevel) {
    return logLevel;
  }

  throw new Error(`Error setting log level: unknown environment '${APP_ENV}'`);
}

const IS_BROWSER = typeof window !== 'undefined';
export const IS_CHROME = IS_BROWSER && typeof window.chrome !== 'undefined';

export const CHROME_EXTENSION_ID = 'lcmcogdbacglippglogllehffhldfmem';

export function getCurrentEnvironment(): Environment {
  return APP_ENV;
}

/**
 * ApplicationConfiguration to avoid using APP_ENV everywhere
 */
export const ApplicationConfiguration = {
  logQueryErrorsJson: APP_ENV !== Environment.Test,
  collectPrefabEvalutions: APP_ENV !== Environment.Development,
  configureFullStory: IS_BROWSER && APP_ENV === Environment.Production,
  configureVercelToolbar: APP_ENV === Environment.Development || APP_ENV === Environment.Uat,
  configureDataDogTransport: IS_BROWSER && APP_ENV === Environment.Production,
  configurePosthog: IS_BROWSER,
  configureI18nDebugMode: APP_ENV === Environment.Development,
  enableActionCableLogging: ENABLED_ACTION_CABLE_LOGGING === 'true',
  useMockingSequenceDefaultValue: APP_ENV === Environment.Development,
  usePersistedQueryMap: VERCEL_ENV === VercelEnv.Development,
  reuseRelayEnvironment: APP_ENV === Environment.Test,
  skipNoPlusInEmailValidation: VERCEL_ENV === VercelEnv.Preview,
  showUATBadge: APP_ENV === Environment.Uat,
  showDevBadge: APP_ENV === Environment.Development,
  showGraphiql: APP_ENV === Environment.Development,
};
